import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { injectIntl, FormattedMessage } from 'react-intl';
import Input from '../form/Input';
import BottomBar from '../BottomBar';
import { yesNo } from '../helpers';
import RadioButton from '../form/RadioButton';
import {
  updatePersonalDetails,
  updatePreferences,
  updateProviderDetails,
  updatePhoneNumbers,
  updateAddress,
  updatePracticeDetails,
} from '../../../actions/preferences';
import { ADVANCE_REGISTRATION, logEvent } from '../../../utils/amplitude';

const errorStyle = {
  color: 'red',
  fontSize: '12px',
  whiteSpace: 'nowrap',
};
class TaxInformation extends Component {
  componentDidMount() {}

  onSubmit = values => {
    const errorMessage = (
      <FormattedMessage defaultMessage="There was a problem updating your tax information" />
    );
    const parsedValues = {
      custom_tax_yn: values.custom_tax_yn,
      gst_hst_number: values.gst_hst_number,
      pst_number: values.pst_number,
    };

    this.props.openLoadingPanel(
      <FormattedMessage defaultMessage="Updating Tax Information" />,
    );
    Promise.all([this.props.updateProviderDetails(parsedValues)])
      .then(() => {
        logEvent(ADVANCE_REGISTRATION, { page: 'tax information' });
        this.props.closeLoadingPanel();

        this.props.nextPage();
      })
      .catch(error => this.props.errorCallback(error, errorMessage));
  };

  render() {
    const { handleSubmit, intl, displayHstField } = this.props;
    const { formatMessage } = intl;
    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className="page-title" style={{ marginBottom: '20px' }}>
          <FormattedMessage tagName="h1" defaultMessage="Tax Information" />
        </div>
        <h4 style={{ marginBottom: '40px' }}>
          <FormattedMessage defaultMessage="We need the following information to make payments to your account. This information is confidential and will be deleted if you choose to deactivate your GreenShield Health account" />
        </h4>
        <p className="onboarding-label">
          {`${formatMessage({
            defaultMessage: 'Are your clinical services tax exempt?',
          })} *`}
        </p>
        <div className="input-row">
          <Field component={RadioButton} name="custom_tax_yn" options={yesNo} />
        </div>
        <>
          <p className="onboarding-label">
            {`${formatMessage({
              defaultMessage:
                'Are you registered to charge taxes for your services?',
            })} *`}
          </p>
          <p className="onboarding-sub-label">
            <FormattedMessage defaultMessage="We require a registered tax number in order to pay out taxes on applicable clinical services. An HST or GST number is mandatory for all provinces and territories, but a PST, RST, or QST number may be required depending on the province that you practice in. Your HST or GST number is a 15 digit code made up of 9 numbers, followed by 2 letters, followed by 4 more numbers, and should have the following format: 123456789RT0001. Please consult our knowledge base for additional details" />
          </p>
          <div className="input-row">
            <Field component={RadioButton} name="hst_yn" options={yesNo} />
          </div>
        </>
        {displayHstField && (
          <>
            <p className="onboarding-label">
              {`${formatMessage({
                defaultMessage: 'HST or GST number (required)',
              })} *`}
            </p>
            <div className="input-row">
              <Field
                component={Input}
                name="gst_hst_number"
                maxLength={15}
                errorStyle={errorStyle}
              />
            </div>
            <p className="onboarding-label">
              {formatMessage({
                defaultMessage: 'PST, RST, or QST number (if applicable)',
              })}
            </p>
            <div className="input-row">
              <Field
                component={Input}
                name="pst_number"
                maxLength={15}
                errorStyle={errorStyle}
              />
            </div>
          </>
        )}
        <BottomBar {...this.props} />
      </form>
    );
  }
}

TaxInformation.propTypes = {
  change: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  openLoadingPanel: PropTypes.func.isRequired,
  closeLoadingPanel: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  nextPage: PropTypes.func.isRequired,
  openErrorDialog: PropTypes.func.isRequired,
  updatePersonalDetails: PropTypes.func.isRequired,
  updatePreferences: PropTypes.func.isRequired,
  updateProviderDetails: PropTypes.func.isRequired,
  updatePhoneNumbers: PropTypes.func.isRequired,
  updateAddress: PropTypes.func.isRequired,
  updatePracticeDetails: PropTypes.func.isRequired,
  otherReferral: PropTypes.bool.isRequired,
  errorCallback: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  states: PropTypes.array.isRequired,
  displayHstField: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
  const selector = formValueSelector('PracticeOnboardingForm');
  const displayHstField = selector(state, 'hst_yn') === 'true';

  return {
    displayHstField,
  };
};

const validate = values => {
  const errors = {};

  if (!values.custom_tax_yn) {
    errors.custom_tax_yn = <FormattedMessage defaultMessage="Required" />;
  }

  if (!values.hst_yn) {
    errors.hst_yn = <FormattedMessage defaultMessage="Required" />;
  }
  if (values.hst_yn && !values.gst_hst_number) {
    errors.gst_hst_number = <FormattedMessage defaultMessage="Required" />;
  }

  const alphanumericPattern = /^\d{9}[A-Za-z]{2}\d{4}$/i;
  if (
    values.gst_hst_number &&
    !alphanumericPattern.test(values.gst_hst_number)
  ) {
    errors.gst_hst_number = (
      <FormattedMessage defaultMessage="Your HST or GST number must have the format 123456789RT0001" />
    );
  }
  return errors;
};

export default injectIntl(
  reduxForm({
    form: 'PracticeOnboardingForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  })(
    connect(mapStateToProps, {
      updatePersonalDetails,
      updatePreferences,
      updateProviderDetails,
      updatePhoneNumbers,
      updateAddress,
      updatePracticeDetails,
    })(TaxInformation),
  ),
);
