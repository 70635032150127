import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ScrollDown from './ScrollDown';
import { isFullyScrolled } from './scroll';
import { steppers } from './data';

class Panel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fullyScrolled: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentStep !== prevProps.currentStep) {
      // eslint-disable-next-line
      this.setState({ fullyScrolled: false });
    }
  }

  classNames = value => {
    const { currentStep } = this.props;
    let className = '';

    if (value < currentStep) {
      className = 'completed-step';
    } else if (value === currentStep) {
      className = 'current-step';
    }

    return className;
  };

  render() {
    const { children } = this.props;
    const { fullyScrolled } = this.state;

    return (
      <div className="panel">
        {steppers.map(({ value, label }) => (
          <div
            className="side-stepper flex-center"
            key={value}
            style={{ top: `calc(10px + ${value * 60}px)` }}
          >
            <div
              className={`stepper-circle flex-center ${this.classNames(value)}`}
            >
              {value + 1}
            </div>
            <div className={`stepper-label ${this.classNames(value)}`}>
              {label}
            </div>
          </div>
        ))}
        <div
          id="panel-content"
          className="panel-content"
          onScroll={() => isFullyScrolled(this)}
        >
          {children}
        </div>
        {!fullyScrolled && <ScrollDown />}
      </div>
    );
  }
}

Panel.propTypes = {
  children: PropTypes.node,
  currentStep: PropTypes.number.isRequired,
};

Panel.defaultProps = {
  children: null,
};

export default Panel;
