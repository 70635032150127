import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import Avatar from 'material-ui/Avatar';

import BottomBar from '../BottomBar';
import TextArea from '../form/TextArea';
import FileUpload from '../../global/FileUpload';
import { updateUser } from '../../../actions/user';
import { updateProviderBio } from '../../../actions/preferences';

class MatchingPreferences extends Component {
  onSubmit = values => {
    const errorMessage = (
      <FormattedMessage defaultMessage="There was a problem updating your matching preferences." />
    );
    const userValues = new FormData();
    if (values.avatar) userValues.append('avatar', values.avatar[0]);
    const providerBioValues = { provider_bio: { overview: values.overview } };

    this.props.openLoadingPanel(
      <FormattedMessage defaultMessage="Updating matching preferences..." />,
    );
    Promise.all([
      this.props.updateUser(userValues),
      this.props.updateProviderBio(providerBioValues),
    ])
      .then(() => {
        this.props.closeLoadingPanel();
        this.props.nextPage();
      })
      .catch(error => this.props.errorCallback(error, errorMessage));
  };

  fileSizeError = () =>
    this.props.openErrorDialog(
      <FormattedMessage defaultMessage="There was a problem with the file you selected. Please check to make sure your file meets the max size limit" />,
    );

  renderAvatar = () => {
    const { selectedAvatar } = this.props;

    let avatar = <noscript />;

    if (selectedAvatar) {
      avatar = (
        <Avatar
          src={selectedAvatar[0].preview}
          size={200}
          style={{
            marginBottom: '20px',
            objectFit: 'cover',
          }}
        />
      );
    }

    return avatar;
  };

  render() {
    const { handleSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <div className="page-title" style={{ marginBottom: '20px' }}>
          <FormattedMessage
            tagName="h1"
            defaultMessage="Matching Preferences"
          />
        </div>
        <div style={{ marginBottom: '40px' }}>
          <FormattedMessage
            tagName="h4"
            defaultMessage="If you are interested in accessing new clients through GreenShield Health, we will need a photo and a profile description. Otherwise, skip this screen"
          />
        </div>
        <Field
          accept=".jpg, .jpeg, .png"
          name="avatar"
          component={FileUpload}
          label={
            <FormattedMessage defaultMessage="Please upload a headshot that is friendly yet professional:" />
          }
          onDropRejected={this.fileSizeError}
        />
        {this.renderAvatar()}
        <FormattedMessage
          tagName="h3"
          defaultMessage="Please describe your practice and give clients a sense of the benefits they will get from therapy with you:"
        />
        <div className="input-row">
          <Field component={TextArea} name="overview" />
        </div>
        <BottomBar {...this.props} />
      </form>
    );
  }
}

MatchingPreferences.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  openErrorDialog: PropTypes.func.isRequired,
  openLoadingPanel: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  updateProviderBio: PropTypes.func.isRequired,
  closeLoadingPanel: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  errorCallback: PropTypes.func.isRequired,
  selectedAvatar: PropTypes.any,
};

MatchingPreferences.defaultProps = {
  selectedAvatar: null,
};

const selector = formValueSelector('PracticeOnboardingForm');
const mapStateToProps = state => ({
  selectedAvatar: selector(state, 'avatar'),
});

const validate = values => {
  const errors = {};

  if (!values.avatar)
    errors.avatar = <FormattedMessage defaultMessage="Required" />;

  if (!values.overview)
    errors.overview = <FormattedMessage defaultMessage="Required" />;

  return errors;
};

export default connect(mapStateToProps, {
  updateUser,
  updateProviderBio,
})(
  reduxForm({
    form: 'PracticeOnboardingForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  })(MatchingPreferences),
);
